import React, { useEffect, useRef, useState } from "react";
import OgImg from "../assets/images/raster/components/common/mainscreen.png";
import { HEADING_TYPE } from "../helpers/constants";
import { SHOW_OFFER } from "../components/instruction/Offer/index.content";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { PageContext } from "../store/context/page-context";
import { getTrueClasses } from "../helpers";
import LayoutLocalized from "../layouts/LayoutLocalized";
import Seo from "../components/Seo";
import "../assets/scss/terms-of-use/index.scss";
// import InfoSection from "../components/instruction/InfoSection";
import { graphql } from "gatsby";
import Offer from "../components/instruction/Offer";
import Guide from "../components/instruction/Guide";
import "../components/instruction/index.scss";
import "../components/instruction/local.scss";

const PgOI = () => {
  const { t } = useTranslation();
  const [, setCurrentLocale] = useState(null);
  const guideRef = useRef(null);
  const language = t("code");
  const showOffer = SHOW_OFFER.includes(language);

  const transitionToRef = (refParameter) => {
    console.log("guide");
    !!refParameter &&
      !!refParameter.current &&
      refParameter.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
  };

  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      window.location.hash === "#guide" &&
      showOffer
    ) {
      transitionToRef(guideRef);
    }
  }, []);

  return (
    <PageContext.Provider
      value={{
        isOaVersion: true,
        language,
        translate: t,
        showOffer,
      }}
    >
      <LayoutLocalized
        customClass={getTrueClasses(
          "PgI",
          language !== "en" && `PgI_${language}`
        )}
        setLocale={setCurrentLocale}
        headingType={HEADING_TYPE.STANDARD}
      >
        <Seo
          title="Here you can find a short user’s guide for our product | AMZScout"
          description="Here you can find a short user’s guide for our product | AMZScout"
          page={
            language !== "en" ? `${language}/oa-instruction` : "oa-instruction"
          }
          manifest="browserconfig.xml"
          ogImg={OgImg}
        />
        {showOffer && <Offer />}
        <Guide guideRef={guideRef} />
      </LayoutLocalized>
    </PageContext.Provider>
  );
};

export default PgOI;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { language: { eq: $language }, ns: { eq: "instruction" } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
